@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');


.App{
  min-height: 100vh;
  display:flex;
  background-image: url('./background.png');
  background-position: center;
  background-size: cover;
  color:black;
}